import React from 'react';
import PropTypes from "prop-types"
import { graphql, Link, StaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import './footer.scss';

const Footer = ({data}) => {
  return (
    <StaticQuery
      query={graphql`
          query FooterQuery {
            strapiAllgemein {
              Links {
                facebook
              }
            }
           }
        `}
      render={(queryData) => (
        <footer className="footer">
          <div className="footer__content">
            <div className="footer__copyright">&copy; {`${new Date().getFullYear()} \u00AB ${data.site.siteMetadata.title} \u00bb | Alle Rechte vorbehalten | Umgesetzt durch `}<a href="https://mediaoberberg.de/">MediaOberberg</a></div>
            <div className="footer__links">
              <ul className="footer__link-list">
                <li className="footer__link-item"><a className="footer__link" href={queryData.strapiAllgemein.Links.facebook}><FontAwesomeIcon className="" icon={faFacebook}/> Facebook</a></li>
                <li className="footer__link-item"><Link className="footer__link" to="/datenschutz">Datenschutz</Link></li>
                <li className="footer__link-item"><Link className="footer__link" to="/impressum">Impressum</Link></li>
              </ul>
            </div>
          </div>
        </footer>
      )}
    />
  );
};

Footer.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Footer;
