import React from 'react';
import PropTypes from 'prop-types';
import './container.scss';

const Container = ({children, isFluid, bgColor, classNames}) => {
  const getClassNames = () => {
    let classNames = '';
    switch (bgColor) {
      case 'accentMain':
        classNames += ' container_bg_accent-main';
        break;
      case 'lightbrown':
        classNames += ' container_bg_accent-alt';
        break;
      // no default
    }
    return classNames;
  }

  return (
    <div className={getClassNames()}>
      <div className={(isFluid ? '' : 'container__content') + ` ${classNames || ''}`}>
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  isFluid: PropTypes.bool,
  bgColor: PropTypes.oneOf(['lightbrown', 'white']),
  classNames: PropTypes.string
};

export default Container;
