import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby';
import CSSTransition from 'react-transition-group/cjs/CSSTransition';
import './header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../contexts/authContext';

const temp = {
  phone: {text: '02297 - 909 06 06', link: '+4922979090606'},
  mail: {text: 'info@aelpida-pflege.de'}
}

const Header = ({siteTitle, menuItems}) => {
  // const menuItemss = data.allSitePage.nodes;
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const auth = useContext(AuthContext);

  const handleLogout = () => {
    auth.logout();
    setDrawerIsOpen(false);
  }

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          allSitePage {
            nodes {
              path
              context {
                pageTitle
                menuPosition
                isMenuItem
              }
            }
          }
        }
      `}
      render={({allSitePage: {nodes: menuItemsNEW}}) => (
        <header className="navbar">
          <div className="navbar__background">
            <div className="navbar__content">
              <div className="navbar__logo nav-logo">
                <Link to="/" className="nav-logo__link">
                  <img className="nav-logo__img" src={require('../images/logo_aelpida.png')} alt={siteTitle}/>
                </Link>
              </div>
              <ul className="navbar__links">
                <li className="navbar__link-item">
                  <Link className="navbar__link" to="/" activeClassName="navbar__link_active">Home</Link>
                </li>
                {menuItemsNEW.filter(item => item.context && item.context.isMenuItem).sort((a, b) => a.context.menuPosition - b.context.menuPosition).map((item) => (
                  <li key={item.path} className="navbar__link-item">
                    <Link className="navbar__link" to={item.path} activeClassName="navbar__link_active">{item.context.pageTitle}</Link>
                  </li>
                ))}
                <li className="navbar__link-item">
                  <Link className="navbar__link" to="/kontakt" activeClassName="navbar__link_active">Kontakt</Link>
                </li>
              </ul>
              <div className="navbar__contact nav-contact">
                <a className="nav-contact__link" href={`tel:${temp.phone.link}`}>
                  <img className="nav-contact__icon" src={require('../images/aelpida-tel.png')} alt="telephone icon"/>
                  <span className="nav-contact__text">{temp.phone.text}</span>
                </a>
                <a className="nav-contact__link" href={`mailto:${temp.mail.text}`}>
                  <img className="nav-contact__icon" src={require('../images/aelpida-mail.png')} alt="mail icon"/>
                  <span className="nav-contact__text">{temp.mail.link ? temp.mail.link : temp.mail.text}</span>
                  <span>{drawerIsOpen}</span>
                </a>
                {!auth.token && (
                  <Link className="nav-contact__link nav-contact__login" to="/intern/login">
                    <div className="nav-contact__login-icon">
                      <FontAwesomeIcon className="" icon={faSignInAlt} fixedWidth={true}/>
                    </div>
                    <span className="nav-contact__text">Login</span>
                  </Link>
                )}
                {auth.token && (
                  <button className="nav-contact__link nav-contact__login nav-contact__login-button" onClick={handleLogout}>
                    <div className="nav-contact__login-icon">
                      <FontAwesomeIcon className="" icon={faSignOutAlt} fixedWidth={true}/>
                    </div>
                    <span className="nav-contact__text">Logout</span>
                  </button>
                )}
              </div>
              <button className="navbar__hamburger" onClick={() => setDrawerIsOpen(!drawerIsOpen)}/>
            </div>
          </div>
          {/*{drawerIsOpen && (*/}
          <CSSTransition in={drawerIsOpen} timeout={200} classNames="menu-dropdown_state">
            <div className="navbar__menu-dropdown menu-dropdown">
              <ul className="menu-dropdown__items">
                <li className="menu-dropdown__item">
                  <Link className="menu-dropdown__link" to="/" activeClassName="menu-dropdown__link_active">Home</Link>
                </li>
                {menuItemsNEW.filter(item => item.context && item.context.isMenuItem).sort((a, b) => a.context.menuPosition - b.context.menuPosition).map((item) => (
                  <li key={item.path} className="menu-dropdown__item">
                    <Link to={item.path} className="menu-dropdown__link" activeClassName="menu-dropdown__link_active">{item.context.pageTitle}</Link>
                  </li>
                ))}
                <li className="menu-dropdown__item">
                  <Link className="menu-dropdown__link" to="/kontakt" activeClassName="menu-dropdown__link_active">Kontakt</Link>
                </li>
              </ul>
              <div className="menu-dropdown__contact nav-contact_dropdown">
                <a className="nav-contact__link" href={`tel:${temp.phone.link}`}>
                  <img className="nav-contact__icon" src={require('../images/aelpida-tel.png')} alt="telephone icon"/>
                  <span className="nav-contact__text">{temp.phone.text}</span>
                </a>
                <a className="nav-contact__link" href={`mailto:${temp.mail.text}`}>
                  <img className="nav-contact__icon" src={require('../images/aelpida-mail.png')} alt="mail icon"/>
                  <span className="nav-contact__text">{temp.mail.link ? temp.mail.link : temp.mail.text}</span>
                  <span>{drawerIsOpen}</span>
                </a>
              </div>
              <div className="menu-dropdown__contact nav-contact_dropdown nav-login_dropdown">
                {!auth.token && (
                  <Link to="/intern/login" className="nav-contact__link">
                    <div className="nav-login__icon">
                      <FontAwesomeIcon className="" icon={faSignInAlt} fixedWidth={true}/>
                    </div>
                    <span className="nav-contact__text nav-login__text">Login</span>
                  </Link>
                )}
                {auth.token && (
                  <button onClick={handleLogout} className="nav-contact__link nav-login__button">
                    <div className="nav-login__icon">
                      <FontAwesomeIcon className="" icon={faSignOutAlt} fixedWidth={true}/>
                    </div>
                    <span className="nav-contact__text nav-login__text">Logout</span>
                  </button>
                )}
              </div>
            </div>
          </CSSTransition>
          {/*)}*/}
        </header>
      )}
    />
    )
}


Header.propTypes = {
  siteTitle: PropTypes.string,
  menuItems: PropTypes.array
}

Header.defaultProps = {
  siteTitle: ``,
  menuItems: [
    {text: 'Test 1', link: '#'},
    {text: 'Test 2', link: '#'},
    {text: 'Test 3', link: '#'},
    {text: 'Test 4', link: '#'}
  ]
}

export default Header
